import React, {useState}from 'react'
import { Segment, Header, Grid, Table } from 'semantic-ui-react'
import ProcessingResults from './ProcessingResults';

const POSITIVE_COLOR ='positive'
const NEGATIVE_COLOR = 'negative'
const WARNING_COLOR = 'warning'


const Results = ({client, questionnaire, navigator} ) => {
    const [results, setResults] = useState(null)

    const handleResults = (results) => {
        if (results) setResults({...results})
        
    }

    return(
        <Segment raised>
            <Grid textAlign='center'>
                <Grid.Row>
                    <Header>Resultados</Header>
                </Grid.Row>
                <Grid.Row>
                    <ProcessingResults client={client} questionnaire={questionnaire}  navigator={navigator} handleResults={handleResults} />
                </Grid.Row>
                { results ?
                <Grid>
                    <Grid.Row>
                        <Table celled>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell colSpan='2'>Resultados</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                <Table.Row >
                                    <Table.Cell>Score</Table.Cell>
                                    <Table.Cell >{results.totalScore}/1000</Table.Cell>
                                </Table.Row>
                                <Table.Row >
                                    <Table.Cell>Decisión</Table.Cell>
                                    <Table.Cell positive={results.scoreDecision.color === POSITIVE_COLOR} negative={results.scoreDecision.color === NEGATIVE_COLOR} warning={results.scoreDecision.color === WARNING_COLOR}>{results.scoreDecision.value}</Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell>Rating</Table.Cell>
                                    <Table.Cell positive={results.riskRating.color === POSITIVE_COLOR} negative={results.riskRating.color === NEGATIVE_COLOR} warning={results.riskRating.color === WARNING_COLOR}>{results.riskRating.value}</Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell>Probabilidad de Default</Table.Cell>
                                    <Table.Cell positive={results.defaultProbability.color === POSITIVE_COLOR} negative={results.defaultProbability.color === NEGATIVE_COLOR} warning={results.defaultProbability.color === WARNING_COLOR}>{results.defaultProbability.value}%</Table.Cell>
                                </Table.Row>
                            </Table.Body>
                        </Table>
                    </Grid.Row>
                    <Grid.Row>
                        <Table celled>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell colSpan='3'>Árbol de Decisión</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                <Table.Row >
                                    <Table.Cell>Código</Table.Cell>
                                    <Table.Cell>Descripción</Table.Cell>
                                    <Table.Cell>Decisión</Table.Cell>
                                </Table.Row>
                                <Table.Row  positive={results.rule.color === POSITIVE_COLOR} error={results.rule.color === NEGATIVE_COLOR} warning={results.rule.color === WARNING_COLOR}>
                                    <Table.Cell >{results.rule.code}</Table.Cell>
                                    <Table.Cell >{results.rule.description}</Table.Cell>
                                    <Table.Cell >{results.rule.decision}</Table.Cell>
                                </Table.Row>
                            </Table.Body>
                        </Table>
                    </Grid.Row>
                    <Grid.Row>
                        <Table celled>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell colSpan='5'>Pagos</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                <Table.Row >
                                    <Table.Cell>Monto Solicitado</Table.Cell>
                                    <Table.Cell>Monto de Entrada</Table.Cell>
                                    <Table.Cell>Cuota Mensual</Table.Cell>
                                    <Table.Cell># Meses</Table.Cell>
                                    <Table.Cell>Interés</Table.Cell>

                                </Table.Row>
                                <Table.Row  positive>
                                    <Table.Cell >{results.reqAmount}</Table.Cell>
                                    <Table.Cell >{results.downPayment}</Table.Cell>
                                    <Table.Cell >{results.payments}</Table.Cell>
                                    <Table.Cell >{results.loanTerm}</Table.Cell>
                                    <Table.Cell >6%</Table.Cell>
                                </Table.Row>
                            </Table.Body>
                        </Table>
                    </Grid.Row>
                </Grid>
                : null
                }
            </Grid>
        </Segment>
    )
}

export default Results