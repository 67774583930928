import React from 'react';
import {Input, Icon} from 'semantic-ui-react'

function MultiInput(props) {
    const {handleChange, value, iconName, readOnly} = props

    function validateIsNumber(e){
        const re = /[0-9]+/g;
        if (!re.test(e.key)) {
            e.preventDefault();
        }
    }
    return(
        <div>
        <Input readOnly={readOnly} icon iconPosition='left' onKeyPress={e => validateIsNumber(e)} onChange={(e)=> handleChange(e.target.value)} value={value} required>
            <Icon name={iconName}/>
            <input />
        </Input>
      </div>
        
    )
}

export default MultiInput;