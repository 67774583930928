import React, {useState, useEffect} from 'react';
import {Segment, Grid, Header, Button, Form, Select, Input} from 'semantic-ui-react'


const ID_FILE_INPUT = 'idFileInput'
const UTILITY_FILE_INPUT = 'utilityFileInput'


const DocumentsComponent = ({handleSetDocuments, documents, readOnly}) => {
  let idFileInputRef = null
  let utilityfileInputRef = null

  const [idFile, setIdFile] = useState(null)
  const [utilityFile, setUtilityFile] = useState(null)

  useEffect(()=>{
    if(documents){
      setIdFile(documents.idFile)
      setUtilityFile(documents.utilityFile)
    }
  }, [documents])

  const onClick = (target) =>{
    // () => fileInputRef.current.click()
    switch(target){
      case ID_FILE_INPUT:
        idFileInputRef.click()
        break;
      case UTILITY_FILE_INPUT:
        utilityfileInputRef.click()
        break;
      default:
        break;
    }
  }

  const fileChange = (e) => {
    switch(e.target.id){
      case ID_FILE_INPUT:
        setIdFile(e.target.files[0])
        break;
      case UTILITY_FILE_INPUT:
      setUtilityFile(e.target.files[0])
        break;
      default:
        break;
    }
  }

  function onSubmitHandler() {
    handleSetDocuments({idFile, utilityFile})

  }
  return (
    <Segment raised>
      <Grid textAlign='center'>
          <Grid.Row>
              <Header>Documentos</Header>
          </Grid.Row>
          <Grid.Row textAlign='left'>
            <Form>
              <Form.Field>
                <label>Documento de Identidad</label>
                <Input 
                  action={{ color: 'teal', labelPosition: 'left', icon: 'file', content: 'Seleccionar', onClick:()=>onClick(ID_FILE_INPUT), disabled:readOnly}}
                  actionPosition='left'
                  value={idFile ? idFile.name : ''}
                  readOnly
                />
              </Form.Field>
              <Form.Field>
                <label>Planilla de Luz</label>
                <Input 
                  action={{ color: 'teal', labelPosition: 'left', icon: 'file', content: 'Seleccionar', onClick:()=>onClick(UTILITY_FILE_INPUT), disabled:readOnly}}
                  actionPosition='left'
                  value={utilityFile ? utilityFile.name : ''}
                  readOnly
                />
              </Form.Field>
              
              <Button disabled={!(idFile && utilityFile)} color='blue' onClick={onSubmitHandler}>Enviar</Button>
              
              </Form>
              <input
                  ref={(input) => (idFileInputRef = input)}
                  id="idFileInput"
                  type="file"
                  onChange={fileChange}
                  hidden
                />
              <input
                    ref={(input) => (utilityfileInputRef = input)}
                    id="utilityFileInput"
                    type="file"
                    hidden
                    onChange={fileChange}
                  />
          </Grid.Row>
      </Grid>
    </Segment>
  );
};

export default DocumentsComponent;