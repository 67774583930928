import React, {useState, useEffect} from 'react';
import {Label} from 'semantic-ui-react'

function MultiInput(props) {
    const {handleChange, readOnly, options, value, single} = props

    const [opts, setOpts] = useState([])

    useEffect(() => {

        if(value && !readOnly) setOpts(value)
        else setOpts(options.map(option=> { return {value: option, selected: !!readOnly}}))
    }, [options, value])


    function handleClick(e, index){
        if(readOnly) return 
        e.preventDefault()
        const copy = setCopy()
        copy[index].selected = !copy[index].selected
        setOpts(copy)

        handleChange(copy)
    }

    function setCopy(){
        if(single){
            return options.map(option=>{return {value: option, selected: false}}).slice(0)
        } else {
            return opts.slice(0)
        }
    }

    return(
        <div>
            {opts.map((option, index) => <Label style={{cursor: 'pointer'}} size='big' color='blue' basic={!option.selected} key={option.value} onClick={(e) => handleClick(e, index)} active={option.selected}>{option.value}</Label>)}
        </div>
    )
}

export default MultiInput;