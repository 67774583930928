import React from 'react'
import { Form, Grid, Header} from 'semantic-ui-react'
import GenericInput from './GenericInput'

const formField = (question, index) => {
    return(
        <Form.Field key={index}>
            <label>
                {question.fieldName}
            </label>
            {/* <Input value= {question.input}/> */}
            <GenericInput readOnly={true} question={question} type={question.type} value={question.input} iconName={question.iconName || '' } options = {question.type === 'multi' && question.input.length ? question.input.filter(op => op.selected === true).map(op => op.value): null}/>
        </Form.Field>
    )
}
const ReviewForm = (props) => {
    const {questions} = props

    return(
        <Grid textAlign='center'>
            <Grid.Row>
                <Header>Revisa la información</Header>
            </Grid.Row>
            <Grid.Row>
                <Form>
                    {questions.map((q, index) => formField(q, index))}
                </Form>
            </Grid.Row>
        </Grid>
    )
}

export default ReviewForm