import React, {useEffect} from 'react';
import TextInput from './TextInput'
import NumberInput from './NumberInput'
import MultiInput from './MultiInput'

function GenericInput(props) {
    const {type, handleChange, handleSelectChange, value, iconName, readOnly, options, single} = props


    return(
        <div>
        {
            type === 'text' ?
            <TextInput handleChange={handleChange} value={value} readOnly={readOnly}/>
            : type === 'number' ?
            <NumberInput handleChange={handleChange} value={value} iconName={iconName} readOnly={readOnly}/>
            : type === 'multi' ?
            <MultiInput handleChange={handleSelectChange} value={value} options={options} readOnly={readOnly} single={single}/>
            :
            'dono'
        }
        </div>
    )
}

export default GenericInput;