import React, {useState, useEffect} from 'react';
import  { Header, Grid, Form, Label } from 'semantic-ui-react'
import GenericInput from './GenericInput'

const QuestionComponent = (props) => {
  const {question, questionnaire, setIsCurrAnswd, setCurrQuestionInput, readOnly} = props

  const [inputIsValid, setInputIsValid] = useState(false)


  useEffect(() => {
    setInputIsValid(validateLimits(question.input || '', question.type, question.min, question.max))
    setIsCurrAnswd(validateLimits(question.input || '', question.type, question.min, question.max))
  }, [question])

  function handleInputChange(value) {
      setCurrQuestionInput(value)
      const valid = validateLimits(value, question.type, question.min, question.max)
      setInputIsValid(valid)
      setIsCurrAnswd(valid)
      if(question.var === 'reqAmount') questionnaire.find(q => q.var === 'downPayment').max = value - 1
}


  function validateLimits(inputValue, inputType, min, max){
    if((inputType === 'number' || inputType === 'text') && !inputValue.length) {
        return false
    }
    return  inputType === 'number' ? inputValue >= min && inputValue <= max : inputType === 'text' ? inputValue.length >= min && inputValue.length <= max : inputType === 'multi' ? selectedLimits(inputValue, min, max) : false
  }

  function selectedLimits(inputValue, min, max) {
      if(!inputValue) return false
      const selectedLength = inputValue.filter(option => option.selected === true).length 
      return selectedLength >= min && selectedLength <= max
  }

  return (
    <div>
        <Grid textAlign='center' verticalAlign='middle'>
            <Grid.Row>
                <Header>{question.description}</Header>
            </Grid.Row>
            <Grid.Row>
                <Label color = {question.input && !inputIsValid ? 'red': !question.input ? 'blue' : 'green'}>{question.inputExpl}</Label>
            </Grid.Row>
            <Grid.Row>
                <Form.Field inline>
                    <GenericInput handleSelectChange= {handleInputChange} handleChange = {handleInputChange} options={question.options} type={question.type} value={ question.input || ''} iconName={question.iconName || ''} single={question.max === 1} readOnly={readOnly}/>
                </Form.Field>
            </Grid.Row>
        </Grid>
    </div>
    )
};
export default QuestionComponent;