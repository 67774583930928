import React, { useEffect, useState} from 'react';
import {Grid, Segment, Button, Placeholder} from 'semantic-ui-react'
import QuestionComponent from './/Question'
import PrevButton from './PrevButton'
import ReviewForm from './ReviewForm';
import {NavLink} from 'react-router-dom'

function BasicInfoComponent(props) {
    const {questionnaire, handleConfirmBasic, readOnly, confirmBasic} = props

    const[isCurrAnswd, setIsCurrAnswd] = useState(false)
    const[currQuestion, setCurrQuestion] = useState(questionnaire[0])
    const[reviewQuestionnaire, setReviewQuestionnaire] = useState(false)
    
    useEffect(()=>{
        setReviewQuestionnaire(confirmBasic)
    }, [confirmBasic])
    function handleClickPrev(){
        const currQuestionIndex = getCurrQuestionIndex()
        const nextQuestionIndex =(((currQuestionIndex - 1) % questionnaire.length) + questionnaire.length) % questionnaire.length
        setCurrQuestion(questionnaire[nextQuestionIndex])
    }
    function handleClickNext(){
        const currQuestionIndex = getCurrQuestionIndex()
        const prevQuestionIndex = (currQuestionIndex + 1) % questionnaire.length
        setCurrQuestion(questionnaire[prevQuestionIndex])
    }

    function handleClickReview() {
        setReviewQuestionnaire(true)
    }

    function handleClickPrevReview(){
        setReviewQuestionnaire(false)
    }

    function setCurrQuestionInput(value){
        questionnaire[getCurrQuestionIndex()].input = value
        setCurrQuestion({...currQuestion, input: value})
    }
    function isFirstQuestion() {

        return getCurrQuestionIndex() === 0
    }

    function isLastQuestion() {
        return getCurrQuestionIndex() === questionnaire.length - 1
    }

    function getCurrQuestionIndex() {
        return questionnaire.indexOf(questionnaire.find(q=> q.var === currQuestion.var))
    }

    return (
        <Segment raised style={{maxHeight:'100%'}}>
            {questionnaire.length && currQuestion ?
            <Grid textAlign='center'>
                <Grid.Row>
                    { !reviewQuestionnaire ?
                    <QuestionComponent setCurrQuestionInput={setCurrQuestionInput} setIsCurrAnswd = {setIsCurrAnswd} question={currQuestion} questionnaire={questionnaire} readOnly={readOnly}/>
                        : <ReviewForm questions={questionnaire}/>
                        
                    }
                    </Grid.Row>
                <Grid.Row>
                    <Grid columns={2} centered verticalAlign='middle'>
                        <Grid.Column textAlign='center'>
                        { !isFirstQuestion() && !reviewQuestionnaire ? 
                            <PrevButton handleClick={handleClickPrev}/>
                            : reviewQuestionnaire && !confirmBasic?
                            <PrevButton handleClick={handleClickPrevReview}/>
                            : null
                        }
                        </Grid.Column>

                        <Grid.Column textAlign='center'>
                        { !isLastQuestion() && !reviewQuestionnaire ?
                            <Button primary disabled={!isCurrAnswd} onClick={handleClickNext} >Siguiente</Button>
                            : !reviewQuestionnaire ? 
                            <Button primary  disabled={!isCurrAnswd} onClick={handleClickReview} >Siguiente</Button>
                            :
                            <Button primary  disabled={!isCurrAnswd} onClick={() => handleConfirmBasic()} >Confirmar</Button>
                        }
                        </Grid.Column>
                    </Grid>
                </Grid.Row>
            </Grid>
            : 
            <Placeholder>
            <Placeholder.Header image>
                <Placeholder.Line />
                <Placeholder.Line />
            </Placeholder.Header>
            <Placeholder.Paragraph>
                <Placeholder.Line length='medium' />
                <Placeholder.Line length='short' />
            </Placeholder.Paragraph>
            </Placeholder>
        }
        </Segment>
    );
};
export default BasicInfoComponent;