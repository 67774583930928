import React, { Component } from 'react';
import { BrowserRouter as Router } from "react-router-dom";
import MainComponent from './components/Main'
import './components/styles.css'
import 'semantic-ui-css/semantic.min.css'
import './App.css';

// library.add(faStroopwafel)

class App extends Component {
  render() {
    return (
      <Router>
        <MainComponent />
      </Router>
    );
  }
}

export default App;
