import React, {useState, useEffect} from 'react';
import {Form, Input, Segment, Grid, Header, Button, Select} from 'semantic-ui-react'
import {NavLink} from 'react-router-dom'
import {DateInput} from 'semantic-ui-calendar-react'


const educationOptions = ['Primaria', 'Secundaria', 'Universidad', 'Masters o superior', 'Ninguno']
const idTypeOptions = ['Cédula', 'Pasaporte']

const getDateFormat=(date)=>{
    let month = (date.getMonth() + 1).toString()
    let day = date.getDate().toString()
    let year = date.getFullYear().toString()

    if(month.length === 1) month = '0' + month
    if(day.length === 1) day = '0' + day


    return `${day}-${month}-${year}`

}
const ClientInfoComponent = ({handleConfirmClient, client, readOnly}) => {
  const today = new Date()
  const year18 = new Date()
  const year100 = new Date()
  const eighteenAgo = new Date(year18.setYear(today.getFullYear() - 18))
  const hundredAgo = new Date(year100.setYear(today.getFullYear() - 100))

  const [form, setForm] = useState({
    firstName: '',
    middleName: '',
    firstLastName: '',
    secondLastName: '',
    birthDate: '',
    id: '',
    idType:'',
    educationLevel: ''
  })

  useEffect(()=>{
    if(client) {
        setForm({
            firstName: client.firstName,
            middleName: client.middleName,
            firstLastName: client.firstLastName,
            secondLastName: client.secondLastName,
            birthDate: client.birthDate,
            id: client.id,
            idType: client.idType,
            educationLevel: client.educationLevel
        })
    }
  }, [client])
  function handleInputChange (event) {
    const { value, name } = event.target;
    setForm({...form, [name]: value})
  }

  function handleDataChange (event, {name, value}) {
    setForm({...form, [name]: value})
  }

  function onSubmit(event) {
      event.preventDefault();
      handleConfirmClient(form)
  }


  return (
    <Segment raised>
      <Grid textAlign='center'>
        <Grid.Row>
            <Header>Información del Cliente</Header>
        </Grid.Row>
        <Grid.Row>
            <Form onSubmit={onSubmit}>
                <Form.Field required>
                        <label>
                            Primer Nombre
                        </label>
                        <Input
                        readOnly={readOnly}
                        required 
                        type="text"
                        name="firstName"
                        placeholder="Primer Nombre"
                        value={form.firstName}
                        onChange={handleInputChange}
                        />
                    </Form.Field>
                    <Form.Field>
                        <label>
                            Segundo Nombre
                        </label>
                        <Input
                        readOnly={readOnly}
                        type="text"
                        name="middleName"
                        placeholder="Segundo Nombre"
                        value={form.middleName}
                        onChange={handleInputChange}
                        />
                    </Form.Field>
                    <Form.Field required>
                        <label>
                            Apellido Paterno
                        </label>
                        <Input
                        readOnly={readOnly}
                        required
                        type="text"
                        name="firstLastName"
                        placeholder="Apellido Paterno"
                        value={form.firstLastName}
                        onChange={handleInputChange}
                        />
                    </Form.Field>
                    <Form.Field required>
                        <label>
                            Apellido Materno
                        </label>
                        <Input
                        readOnly={readOnly}
                        required 
                        type="text"
                        name="secondLastName"
                        placeholder="Apellido Materno"
                        value={form.secondLastName}
                        onChange={handleInputChange}
                        />
                    </Form.Field>
                    <Form.Field required>
                        <label>
                            Tipo de Identificación
                        </label>
                        {client ?

                        <Input 
                            readOnly={readOnly}
                            value={form.idType}
                        />
                        :
                        <Select 
                        name='idType'
                        options={idTypeOptions.map(item => ({text: item, value:item }))} 
                        onChange={handleDataChange}/>
                        }
                    </Form.Field>
                    <Form.Field required>
                        <label>
                            Identificación
                        </label>
                        <Input
                        readOnly={readOnly}
                        required 
                        type="text"
                        name="id"
                        placeholder="Identificación"
                        value={form.id}
                        onChange={handleInputChange}
                        />
                    </Form.Field>
                    <Form.Field required>
                        <label>
                            Fecha de Nacimiento
                        </label>
                        <DateInput
                        readOnly={readOnly}
                        name='birthDate'
                        onChange={handleDataChange}
                        initialDate={getDateFormat(eighteenAgo)}
                        maxDate={getDateFormat(eighteenAgo)}
                        minDate={getDateFormat(hundredAgo)}
                        value={form.birthDate}
                        iconPosition='left'
                        closable={true}
                        />
                    </Form.Field>
                    <Form.Field required>
                        <label>
                            Nivel de Educación
                        </label>
                        {client ?

                        <Input 
                            readOnly={readOnly}
                            value={form.educationLevel}
                        />
                        :
                        <Select 
                        name='educationLevel'
                        options={educationOptions.map(item => ({text: item, value:item }))} 
                        onChange={handleDataChange}/>
                        }
                    </Form.Field>
                    <Button color='blue' type='submit'>Enviar</Button>
                </Form>
            </Grid.Row>
      </Grid>
    </Segment>
  );
};

export default ClientInfoComponent;