export const data = [
    {var: 'reqAmount', fieldName_en: 'Requested Amount', type: 'number', fieldName: 'Monto Solicitado ($)', iconName: 'dollar sign', inputExpl: 'El monto debe ser mayor a $100 y menor a $100,000', description:'Cuánto dinero necesita?', min:100, max:100000},
    {var: 'downPayment', fieldName_en: 'Down Payment Amount', type: 'number', fieldName: 'Monto de Entrada ($)', iconName: 'dollar sign', inputExpl: 'El monto debe ser menor al Monto Solicitado', description:'Cuánto dinero puede pagar por adelantado?', min:0},
    {var: 'loanTerm', fieldName_en: 'Loan Term (months)', type: 'number', fieldName: 'Plazo Solicitado (Meses)' , iconName: 'time', inputExpl: 'Los meses tienen que ser entre 12 y 48',  description:'En cuantos meses lo quiere pagar?', min:12, max:48},
    {var: 'income', fieldName_en: 'Income ($)', type: 'multi', fieldName: 'Ingresos Mesuales ($)' , iconName: 'time', inputExpl: 'Elige solo 1', options: ['$0 - $100', '$100 - $400', '$400 - $1,000', '$1,0000 - $5,000', '$5,000+'], description:'En qué rango está tu ingreso mensual?', min:1, max:1},
    {var: 'importance', fieldName_en: 'Importance', type: 'multi', fieldName: 'Importante', inputExpl: 'Elige solo 1', options: ['Cantidad del préstamo', 'Rapidéz del préstamo', 'Costo del préstamo'], description:'Qué es más importante para ti?', min:1, max:1},
    {var: 'assets', fieldName_en: 'Loan Purpose', type: 'multi', fieldName: 'Bienes del Aplicante', inputExpl: 'Elige hasta 4', options: ['Casa', 'Carro', 'Negocio', 'Otros'], description:'Qué bienes tiene a su nombre?', min:1, max:4},
    {var: 'loanPurpose', fieldName_en: 'Loan Purpose', type: 'multi', fieldName: 'Propósito de Crédito', inputExpl: 'Elige solo 1', options: ['Educación', 'Consumo', 'Carro', 'Negocio', 'Otro'], description:'Para qué utilizará el dinero que le prestemos?', min:1, max:1},
    {var: 'exp', fieldName_en: 'Explication', type: 'text', fieldName: 'Propósito de Crédito - Explicación', min:25, max: 100000, inputExpl: 'Mínimo 25 caracteres', description:'Explíquenos por qué necesita el dinero'},
    {var: 'savings', fieldName_en: 'Savings', type: 'number', fieldName: 'Porcentaje del sueldo que ahorra (%)', iconName: 'percent', inputExpl: 'El porcentaje tiene que estar entre 1% y 100%', min:1, max:100, description:'Qué porcentaje de su sueldo ahorra mensualemente?'},
]