import React from 'react';
import {TextArea} from 'semantic-ui-react'


function TextInput(props) {
    const {handleChange, value, readOnly} = props

    return(
        
        <TextArea readOnly={readOnly} minLength='200' maxLength='1000' placeholder='' onChange={(e) =>handleChange(e.target.value)} value={value}/>
        
    )
}

export default TextInput;