import React from 'react';
import {Icon} from 'semantic-ui-react'

function NextButton(props) {
    return(
        // <Button primary >Prev</Button>
        <Icon link name='arrow alternate circle left outline' size='large' onClick={props.handleClick}/>
    )
}

export default NextButton;