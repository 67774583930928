import React, {useState} from 'react';
import { Menu } from 'semantic-ui-react'
import {NavLink} from 'react-router-dom'

import {BASIC_INFO_URL, CLIENT_INFO_URL, DOCUMENTS_URL, RESULTS_URL} from './urls'



const MenuComponent = ({activePage, setActivePage}) => {

  function handleClick (e) {
    if(e.target.classList.contains('disabled')) e.preventDefault()
  }


  return (
      <Menu color='blue' className='nav' inverted pointing secondary>
        <Menu.Item ><img src='./bdp.jpg' alt='logo'/></Menu.Item>
        <Menu.Item header>Banco del Pacífico</Menu.Item>
        <Menu.Item active={activePage === BASIC_INFO_URL} className={'nav-link'} onClick={() => setActivePage(BASIC_INFO_URL)}>Información</Menu.Item>
        <Menu.Item active={activePage === CLIENT_INFO_URL} className={'nav-link'} onClick={() => setActivePage(CLIENT_INFO_URL)}>Cliente</Menu.Item>
        <Menu.Item active={activePage === DOCUMENTS_URL} className={'nav-link'} onClick={() => setActivePage(DOCUMENTS_URL)}>Documentos</Menu.Item>
        <Menu.Item active={activePage === RESULTS_URL} className={'nav-link'} onClick={() => setActivePage(RESULTS_URL)}>Resultados</Menu.Item>
      </Menu>
  );
};

export default MenuComponent;

